<template>
  <div>
    <h1>Nieuwe notificatie sturen</h1>
    <FormNotificatie v-model="form" @submit="onSubmit" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import useApi from '@/hooks/useApi'
import FormNotificatie from '@/components/Form/Notificatie.vue'

const api = useApi()
const form = ref({})

const onSubmit = data => {
  api('NOTIFICATIE_CREATE', data).then(() => {
    form.value = {}
  })
}
</script>
