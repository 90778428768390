<template>
  <div class="grid 2xl:grid-cols-2 gap-4 max-w-6xl xl:max-w-full mx-auto">
    <div class="card shadow flex flex-col gap-4 items-start">
      <UIInput required v-model="form.content" label="Content" class="w-full max-w-2xl" placeholder="Content ..." />
      <div class="flex flex-col">
        <UISelectFixed type="USER_TYPE" nullable nullable-label="Alle rollen" class="w-full max-w-sm" v-model="form.role" @update:modelValue="form.user_id = $event ? null : form.user_id" />
        <small v-if="userTypeText">{{ userTypeText }}</small>
      </div>
      <UISelectChauffeur active class="w-full max-w-sm" multiple nullable label v-model="form.user_id" @update:modelValue="form.role = $event ? null : form.role" />
      <button type="submit" :disabled="!form.content || (!form.role && !form.user_id)" class="btn success" @click.prevent.stop="onSubmit()">Opslaan</button>
      <small v-if="!form.role && !form.user_id">Gelieve ofwel een rol ofwel een chauffeur te kiezen.</small>
    </div>
  </div>
</template>

<script setup>
import clone from 'just-clone'
import { ref, computed } from 'vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UIInput from '@/components/UI/Input/Input'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'

const props = defineProps({
  modelValue: Object,
})

const emit = defineEmits(['submit'])

let form = ref({
  content: props.modelValue?.content || '',
  role: props.modelValue?.role || null,
  user_id: props.modelValue?.user_id || null,
})

const onSubmit = () => {
  const submitData = clone(form.value)
  emit('submit', submitData)
}

const userTypeText = computed(() => {
  switch (form.value.role) {
    case 'admin': {
      return 'Wordt verzonden naar alles Admins'
    }
    case 'chauffeur': {
      return 'Wordt verzonden naar alle Admins & Chauffeurs (exclusief onderaannemers)'
    }
    case 'onderaannemer': {
      return 'Wordt verzonden naar alle Onderaannemers'
    }
    default:
      return ''
  }
})
</script>
